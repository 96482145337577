<template>
    <div style="margin-bottom:2rem;">
        <div class="flex wrap">
            <div class="col3" style="height:500px;" @click="go" v-for="(item,index) in data" :key="index">
                <Item :data="item" ></Item>
            </div>

        </div>
        <More></More>
    </div>
</template>
<script>
import Item from "@/components/columnItem.vue"
import More from "@/components/More.vue"
export default {
    components:{Item,More},
    data(){
        return{
            data:[
            {url:'case/4-1.jpg',title:'声纹鉴定为电信诈骗案件审理提供有力证据',time:'2021-12-31',content:`犯罪嫌疑人张某通过偷渡的方式出境至缅甸某地打工，经人介绍到某一诈骗窝点参与电信诈骗的犯罪活动，张某利用该诈骗窝点提供的场所和电脑等设备`},
                
                ]
        }
    },
    methods:{
        go(){
            let pathStr = '/case/video_case/1'
            this.$store.commit('set_path',{path:pathStr,query:{}})
        }
    }
}
</script>